import { TransactionStatus } from "@customTypes/transaction"
import countriesList from "./countriesList"

const purposeOfRemittanceOptions = [
  { value: 'COMPUTER_SERVICES', label: 'Computer service' },
  { value: 'FAMILY_SUPPORT', label: 'Family support' },
  { value: 'EDUCATION', label: 'Education' },
  { value: 'GIFT_AND_DONATION', label: 'Gift and other donations' },
  { value: 'MEDICAL_TREATMENT', label: 'Medical treatment' },
  { value: 'MAINTENANCE_EXPENSES', label: 'Maintenance or other expenses' },
  { value: 'TRAVEL', label: 'Travel' },
  { value: 'SMALL_VALUE_REMITTANCE', label: 'Small value remittance' },
  { value: 'LIBERALIZED_REMITTANCE', label: 'Liberalized remittance' },
  { value: 'CONSTRUCTION_EXPENSES', label: 'Construction expenses' },
  { value: 'HOTEL_ACCOMMODATION', label: 'Hotel accommodation' },
  { value: 'ADVERTISING_EXPENSES', label: 'Advertising and/or public relations related expenses' },
  { value: 'ADVISORY_FEES', label: 'Fees for advisory or consulting service' },
  { value: 'BUSINESS_INSURANCE', label: 'Business related insurance payment' },
  { value: 'INSURANCE_CLAIMS', label: 'Insurance claims payment' },
  { value: 'DELIVERY_FEES', label: 'Delivery fees' },
  { value: 'EXPORTED_GOODS', label: 'Payments for exported goods' },
  { value: 'SERVICE_CHARGES', label: 'Payment for services' },
  { value: 'LOAN_PAYMENT', label: 'Payment of loans' },
  { value: 'OFFICE_EXPENSES', label: 'Office expenses' },
  { value: 'PROPERTY_PURCHASE', label: 'Residential property purchase' },
  { value: 'PROPERTY_RENTAL', label: 'Property rental payment' },
  { value: 'ROYALTY_FEES', label: 'Royalty, trademark, patent and copyright fees' },
  { value: 'SHARES_INVESTMENT', label: 'Investment in shares' },
  { value: 'FUND_INVESTMENT', label: 'Fund investment' },
  { value: 'TAX_PAYMENT', label: 'Tax payment' },
  { value: 'TRANSPORTATION_FEES', label: 'Transportation fees' },
  { value: 'UTILITY_BILLS', label: 'Utility bills' },
  { value: 'PERSONAL_TRANSFER', label: 'Personal transfer' },
  { value: 'SALARY_PAYMENT', label: 'Payment of salary' },
  { value: 'REWARD_PAYMENT', label: 'Payment of rewards' },
  { value: 'INFLUENCER_PAYMENT', label: 'Payment of Influencer' },
  { value: 'OTHER_FEES', label: 'Broker, commitment, guarantee and other fees' },
  { value: 'OTHER', label: 'Other purposes' },
]

const idTypeOptions = [
  {value: 'PASSPORT', label: 'Passport'},
  {value: 'NATIONAL_ID', label: 'National ID Card'},
  {value: 'DRIVING_LICENSE', label: 'Driving License'},
  {value: 'SOCIAL_SECURITY', label: 'Social Security Card/Number'},
  {value: 'TAX_ID', label: 'Tax Payer Identification Card/Number'},
  {value: 'SENIOR_CITIZEN_ID', label: 'Senior Citizen Identification Card'},
  {value: 'BIRTH_CERTIFICATE', label: 'Birth Certificate'},
  {value: 'VILLAGE_ELDER_ID', label: 'Village Elder Identification Card'},
  {value: 'RESIDENT_CARD', label: 'Permanent Residency Identification Card'},
  {value: 'ALIEN_REGISTRATION', label: 'Alien Registration Certificate/Card'},
  {value: 'PAN_CARD', label: 'PAN Card'},
  {value: 'VOTERS_ID', label: 'Voter’s Identification Card'},
  {value: 'HEALTH_CARD', label: 'Health Insurance Card/Number'},
  {value: 'EMPLOYER_ID', label: 'Employer Identification Card'},
  {value: 'OTHER', label: 'OTHER'},
]

const getPurposeOfRemittanceTextValue = (value: string) => {
  return purposeOfRemittanceOptions.find(item => item.value === value)?.label || ''
}

const getTxStatus = (status: TransactionStatus) => {
  const list = {
    [TransactionStatus.INITIATED]: 'Initiated',
    [TransactionStatus.CREATED]: 'Created',
    [TransactionStatus.PAYOUT_PROCESSING]: 'Payout processing',
    [TransactionStatus.READY_TO_REDEEM]: 'Ready to redeem',
    [TransactionStatus.COMPLETED]: 'Completed',
    [TransactionStatus.FAILED]: 'Failed',
    [TransactionStatus.EXPIRED]: 'Expired',
    [TransactionStatus.ON_HOLD]: 'On hold',
    [TransactionStatus.REFUNDED]: 'Refunded',
    [TransactionStatus.CANCELED]: 'Canceled',
    [TransactionStatus.REVERSED]: 'Reversed'
  }

  return list[status] ? list[status] : ''
}

const getTxStatusClass = (status: TransactionStatus) => {
  const list = {
    [TransactionStatus.INITIATED]: 'initiated',
    [TransactionStatus.CREATED]: 'created',
    [TransactionStatus.PAYOUT_PROCESSING]: 'payout-processing',
    [TransactionStatus.READY_TO_REDEEM]: 'ready-to-redeem',
    [TransactionStatus.COMPLETED]: 'completed',
    [TransactionStatus.FAILED]: 'error',
    [TransactionStatus.EXPIRED]: 'expired',
    [TransactionStatus.ON_HOLD]: 'on-hold',
    [TransactionStatus.REFUNDED]: 'refunded',
    [TransactionStatus.CANCELED]: 'canceled',
    [TransactionStatus.REVERSED]: 'reversed',
  }

  return list[status] ? list[status] : ''
  // return 'refunded'
}

const getColorByTxStatus = (status: TransactionStatus) => {
  const list = {
    [TransactionStatus.INITIATED]: 'yellow',
    [TransactionStatus.CREATED]: 'yellow',
    [TransactionStatus.PAYOUT_PROCESSING]: '#A9D470',
    [TransactionStatus.READY_TO_REDEEM]: '#A9D470',
    [TransactionStatus.COMPLETED]: '#5AC168',
    [TransactionStatus.FAILED]: '#991b1b',
    [TransactionStatus.EXPIRED]: '#ef4444',
    [TransactionStatus.ON_HOLD]: '#3E409B',
    [TransactionStatus.REFUNDED]: '#A2479F',
    [TransactionStatus.CANCELED]: '#ef4444',
    [TransactionStatus.REVERSED]: '#F59E0B',
  }

  return list[status] ? list[status] : ''
  // return 'refunded'
}

const countriesOptions = countriesList.map(country => ({
  value: country.alpha3,
  label: country.name
}))



const getLabelOfTxDetails = (nameField: string) => {
  const list: Record<string, string> = {
    code: 'Identification code',
    date_of_birth: 'Date of birth',
    country_of_birth_iso_code: 'Country of birth (ISO code)',
    address: 'Address',
    city: 'City',
    country_iso_code: 'Country (ISO code)',
    nationality_country_iso_code: 'Country of nationality',
    msisdn: 'Phone number',
    iban: 'IBAN',
    swift_bic_code: 'SWIFT/BIC code',
    id_type: 'ID type',
    id_number: 'ID number',
    ifs_code: 'IFSC code',
    account_number: 'Account number',
    sort_code: 'Sort code',
    email: 'Email',
    bsb_number: 'BSB number',
    routing_code: 'Routing code/Transit number',
    postal_code: 'Postal code',
    province_state: 'Province/state',
    bank_account_number: 'Bank account Number'
  }

  return list[nameField] ? list[nameField] : ''
}

const txStatusOptions = [
  { value: TransactionStatus.INITIATED, label: 'Initiated' },
  { value: TransactionStatus.CREATED, label: 'Created' },
  { value: TransactionStatus.PAYOUT_PROCESSING, label: 'Payout processing' },
  { value: TransactionStatus.COMPLETED, label: 'Completed' },
  { value: TransactionStatus.FAILED, label: 'Failed' },
  { value: TransactionStatus.EXPIRED, label: 'Expired' },
  { value: TransactionStatus.ON_HOLD, label: 'On hold' },
  { value: TransactionStatus.REFUNDED, label: 'Refunded' },
  { value: TransactionStatus.CANCELED, label: 'Canceled' },
  { value: TransactionStatus.REVERSED, label: 'Reversed' },
]

const transactionUtils = {
  purposeOfRemittanceOptions,
  getTxStatus,
  getLabelOfTxDetails,
  getPurposeOfRemittanceTextValue,
  txStatusOptions,
  getTxStatusClass,
  getColorByTxStatus,
  countriesOptions,
  idTypeOptions,
}

export default transactionUtils