import { createSlice } from "@reduxjs/toolkit"
import { calculationActionCreators } from "../actionCreators/calculationActionCreators"
import { Country, MakeCalculationResponseBody, Payer, Service } from "@customTypes/calculation"
import { LoadState, MetaForList } from "@customTypes/common"
import { transactionActionCreators } from "@actionCreators/transactionActionCreators"
import { TempTxDetails, TxDetails, TxDetailsForList, TxLog } from "@customTypes/transaction"

type TransactionSlice = {
  currentTxDetails: TxDetails | null
  tempCurrentDetails: TempTxDetails | null

  txList: Array<TxDetailsForList>
  metaForGetTransactionList: MetaForList | null
  getTransactionListLoadState: LoadState

  createTransactionLoadState: LoadState

  getTransactionDetailsByIdLoadState: LoadState
  error: boolean
  txLogs: Array<TxLog>

  checkPromoCodeLoadState: LoadState

  lookUpTransactionLoadState: LoadState

  completeCashOutTransactionLoadState: LoadState
}

const initialState: TransactionSlice = {
  currentTxDetails: null,
  tempCurrentDetails: null,

  txList: [],
  metaForGetTransactionList: null,
  getTransactionListLoadState: {
    isLoading: false,
    isLoaded: false,
  },

  createTransactionLoadState: {
    isLoading: false,
    isLoaded: false,
  },

  getTransactionDetailsByIdLoadState: {
    isLoading: false,
    isLoaded: false,
  },
  checkPromoCodeLoadState: {
    isLoading: false,
    isLoaded: false,
  },
  txLogs: [],
  lookUpTransactionLoadState: {
    isLoading: false,
    isLoaded: false,
  },
  error: false,

  completeCashOutTransactionLoadState: {
    isLoading: false,
    isLoaded: false,
  }
}

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    clearCurrentTxDetails: (state) => {
      state.currentTxDetails = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(transactionActionCreators.createTransaction.pending, state => {
        state.createTransactionLoadState = {
          isLoaded: false,
          isLoading: true,
        }
      })
      .addCase(transactionActionCreators.createTransaction.fulfilled, state => {
        state.createTransactionLoadState = {
          isLoaded: true,
          isLoading: false,
        }
      })
      .addCase(transactionActionCreators.createTransaction.rejected, state => {
        state.createTransactionLoadState = {
          isLoaded: false,
          isLoading: false,
        }
      })

      .addCase(transactionActionCreators.getTransactionList.pending, (state, action) => {
        state.txList = action.meta.arg.page === 1 ? [] : state.txList
        state.getTransactionDetailsByIdLoadState = {
          isLoaded: false,
          isLoading: true,
        }
        state.error = false
      })
      .addCase(transactionActionCreators.getTransactionList.fulfilled, (state, action) => {
        state.txList = [...state.txList, ...action.payload.data]
        state.metaForGetTransactionList = action.payload.meta
        state.getTransactionDetailsByIdLoadState = {
          isLoaded: true,
          isLoading: false,
        }
      })
      .addCase(transactionActionCreators.getTransactionList.rejected, state => {
        state.getTransactionDetailsByIdLoadState = {
          isLoaded: false,
          isLoading: false,
        }
        state.error = true
      })
      .addCase(transactionActionCreators.getTransactionDetailsById.pending, state => {
        state.getTransactionDetailsByIdLoadState = {
          isLoaded: false,
          isLoading: true,
        }
      })
      .addCase(transactionActionCreators.getTransactionDetailsById.fulfilled, (state, action) => {
        state.currentTxDetails = action.payload
        state.getTransactionDetailsByIdLoadState = {
          isLoaded: true,
          isLoading: false,
        }
      })
      .addCase(transactionActionCreators.getTransactionDetailsById.rejected, state => {
        state.getTransactionDetailsByIdLoadState = {
          isLoaded: false,
          isLoading: false,
        }
      })

      .addCase(transactionActionCreators.getCashOutTransactionList.pending, (state, action) => {
        state.txList = action.meta.arg.page === 1 ? [] : state.txList
        state.getTransactionDetailsByIdLoadState = {
          isLoaded: false,
          isLoading: true,
        }
        state.error = false
      })
      .addCase(transactionActionCreators.getCashOutTransactionList.fulfilled, (state, action) => {
        state.txList = [...state.txList, ...action.payload.data]
        state.metaForGetTransactionList = action.payload.meta
        state.getTransactionDetailsByIdLoadState = {
          isLoaded: true,
          isLoading: false,
        }
      })
      .addCase(transactionActionCreators.getCashOutTransactionList.rejected, state => {
        state.getTransactionDetailsByIdLoadState = {
          isLoaded: false,
          isLoading: false,
        }
        state.error = true
      })

      .addCase(transactionActionCreators.getCashOutTransactionById.pending, state => {
        state.getTransactionDetailsByIdLoadState = {
          isLoaded: false,
          isLoading: true,
        }
      })
      .addCase(transactionActionCreators.getCashOutTransactionById.fulfilled, (state, action) => {
        state.currentTxDetails = action.payload
        state.getTransactionDetailsByIdLoadState = {
          isLoaded: true,
          isLoading: false,
        }
      })
      .addCase(transactionActionCreators.getCashOutTransactionById.rejected, state => {
        state.getTransactionDetailsByIdLoadState = {
          isLoaded: false,
          isLoading: false,
        }
      })

      .addCase(transactionActionCreators.checkPromoCode.pending, state => {
        state.checkPromoCodeLoadState = {
          isLoaded: false,
          isLoading: true,
        }
      })
      .addCase(transactionActionCreators.checkPromoCode.fulfilled, state => {
        state.checkPromoCodeLoadState = {
          isLoaded: true,
          isLoading: false,
        }
      })
      .addCase(transactionActionCreators.checkPromoCode.rejected, state => {
        state.checkPromoCodeLoadState = {
          isLoaded: false,
          isLoading: false,
        }
      })

      .addCase(transactionActionCreators.lookUpTransaction.pending, state => {
        state.lookUpTransactionLoadState = {
          isLoaded: false,
          isLoading: true,
        }
      })
      .addCase(transactionActionCreators.lookUpTransaction.fulfilled, (state, action ) => {
        state.lookUpTransactionLoadState = {
          isLoaded: true,
          isLoading: false,
        }
        state.tempCurrentDetails = action.payload
      })
      .addCase(transactionActionCreators.lookUpTransaction.rejected, state => {
        state.lookUpTransactionLoadState = {
          isLoaded: false,
          isLoading: false,
        }
      })

      .addCase(transactionActionCreators.completeCashOutTransaction.pending, state => {
        state.completeCashOutTransactionLoadState = {
          isLoaded: false,
          isLoading: true,
        }
      })
      .addCase(transactionActionCreators.completeCashOutTransaction.fulfilled, state => {
        state.completeCashOutTransactionLoadState = {
          isLoaded: true,
          isLoading: false,
        }
      })
      .addCase(transactionActionCreators.completeCashOutTransaction.rejected, state => {
        state.completeCashOutTransactionLoadState = {
          isLoaded: false,
          isLoading: false,
        }
      })

  }
})

export const { clearCurrentTxDetails } = transactionSlice.actions

export default transactionSlice.reducer